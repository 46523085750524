<template>
  <v-dialog v-model="dialog" :width="$vuetify.breakpoint.xs ? '100%' : '30%'">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            elevation="0"
            v-on="{ ...onDialog, ...onTooltip }"
            class=""
            icon
            small
            :style="style_btn"
            :disabled="disabled"
          >
            <v-icon small>$edit</v-icon>
          </v-btn>
        </template>
        {{ $t("edit", { name: $tc("tattooers.token_payment") }) }}
      </v-tooltip>
    </template>
    <v-card id="desing">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("edit", { name: $tc("tattooers.token_payment") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8" v-if="payment_appointment">
              <label v-text="$t('designs.size')"></label>
              <v-select
                :items="token_payments"
                v-model="form.size"
                outlined
                hide-details
                dense
                item-text="size"
                return-object
                @input="select"
              ></v-select>
            </v-col>

            <v-col class="py-0" cols="8" v-if="payment_appointment">
              <label v-text="$t('designs.token_payment')"></label>
              <number-input-with-separators
                :value="form"
                valueKey="token_payment"
                ref="money2"
                rules="required"
                hideDetails
                outlined
                dense
              ></number-input-with-separators>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            outlined
            @click="
              dialog = false;
              $emit('update');
            "
            style="height: 25px"
            elevation="0"
            class="mr-3"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
            >{{ $t("save", { name: "" }) }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      files: [],
      form: {},
      token_payments: [],
    };
  },
  props: [
    "design",
    "appointment_id",
    "appointment",
    "style_btn",
    "tattooer_id",
    "token_payment",
    "disabled",
  ],
  computed: {
    payment_appointment() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "payment_appointment"
      );
      if (s) return s.value === "1";
      return false;
    },
  },
  components: {
    FileUpload,
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.fetch();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("appointments", ["updateAppointmensDesignTokenPayment"]),
    ...mapActions("tattooers", ["getTokenPayments"]),
    fetch() {
      this.getTokenPayments(this.tattooer_id).then((data) => {
        this.token_payments = data;
        this.token_payments.push({ size: this.$t("other_valor") });
      });
      this.form = this.design;
      this.form.token_payment = this.token_payment;
      if (this.$refs.money1) this.$refs.money1.change();
      if (this.$refs.money2) this.$refs.money2.change();
      if (this.$refs.money2) this.$refs.money3.change();
    },
    select() {
      if (this.form.size.type === "percentage") {
        let porcentaje = (this.form.price * this.form.size.price) / 100;
        porcentaje = Math.round(porcentaje * 100) / 100;
        this.form.token_payment = porcentaje;
      } else {
        this.form.token_payment = this.form.size.price;
      }
      this.$refs.money2.change();
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let result = await this.$validator.validateAll(scope);
      let money_val_1 = true;
      let money_val_2 = true;
      let money_val_3 = true;
      if (this.$refs.money1) money_val_1 = await this.$refs.money1.validate();
      if (this.$refs.money2) money_val_2 = await this.$refs.money2.validate();
      if (this.$refs.money3) money_val_3 = await this.$refs.money3.validate();

      if (result && money_val_1 && money_val_2 && money_val_3) {
        //console.log("VALIDADO");
        let formData = new FormData();
        for (let key in this.form) {
          switch (key) {
            case "size":
              if (this.form[key]) formData.append(key, this.form[key].size);
              break;
            default:
              formData.append(key, this.form[key]);
          }
        }
        formData.append("appointment_id", this.appointment_id);
        this.files.forEach((file) => {
          formData.append("files[]", file.file);
        });

        this.updateAppointmensDesignTokenPayment({
          id: this.design.id,
          design: formData,
        }).then((data) => {
          this.dialog = false;
          this.$alert(this.$t("send_ok"));
          this.$store.dispatch("appointments/getPendingAppointments");
          this.$emit("update", data);
        });
      }
    },
  },
};
</script>
<style lang="sass">
#desing
  .file-upload
    label
      height: 70px
  label
    height: 25px
    display: block

.upload
  *
    cursor: pointer
  label
    height: 100% !important
</style>
